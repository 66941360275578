$primary-custom-color: #00095B;

#app-menubar .p-menubar {
	width: 100%;
	display: inline;
	padding: 0%;
}

header {
	position: sticky;
	top: 0;
	z-index: 999;
}

body {
	//overflow : hidden;
	background-color: white;
	max-width: none;
}

.p-messages .p-message.p-message-error{
	background: #ffe7e6;
	border: solid #ff5757;
	border-width: 0 0 0 6px;
	color: #fe4646;
	margin: 1px;
	border-radius: 5px;
}

.p-messages .p-message.p-message-success{
	background: #e4f8f0;
	border: solid #1ea97c;
	border-width: 0 0 0 6px;
	color: #1ea97c;
	margin: 1px;
	border-radius: 5px;
}

.p-tree .p-tree-empty-message{
	text-align: center;
	margin :8px 0px;
}



.p-breadcrumb .p-breadcrumb-list li .p-menuitem-link .p-menuitem-text {
	color: $primary-custom-color
}

.p-breadcrumb .p-breadcrumb-list li.p-menuitem-separator {
	color: $primary-custom-color
}

#menu-bar {
	transition: width 0.3s;
}

footer,
#app-footer {
	background-color: unset !important;
}

#menu-bar.collapsed {
	width: 0px;
	/* Adjust as needed */
}

#menu-bar:not(.collapsed) {
	width: 320px;
	/* Adjust as needed */
}

.p-button.p-button-icon-only:not(.exclude-custom){
	background: transparent;
	color: $primary-custom-color;
	border: none;
	scale: 1.5;
}

.text-no-wrap{
	text-wrap:nowrap;
}
#tabmenu .p-button.p-button-icon-only {
	background: $primary-custom-color;
	color: white;
	scale: 1;
}

.add-shadow {
	box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px;
}

.bg-none {
	background-color: unset !important;
}

.bg-color-1{
	background-color: #f2f2f2
}

.bg-color-2 {
	background-color: #ffffff;
}

.bg-color-3 {
	background-color: #00095B;
}
.bg-color-5 {
	background-color: #f5f6fa;
}

.bg-color-7{
	background-color: #e4e3e3;
}

.tab-view {
	.p-inputtext {
		width: 100%;
	}
}

.text-color-1 {
	color: #00095B !important;
}
.bg-color-6 {
	background-color: #ffffff;
}
.text-color-1{
	color : #00095B !important;
}

.text-color-2{
	color: #800080c4 !important;
}

.custom-ui-tree .p-tree-wrapper {
	padding: 1rem;
}

.p-tabview .p-tabview-nav li .p-tabview-nav-link {
	margin: 0;
}
.text-color-3{
	color:#2861a4 !important;
}

.text-color-4{
	color:#4d4d4d !important;
}

.custom-ui-tree .p-tree-wrapper{
	padding:10px;
}
.bg-color-4{
	color: #f2f2f2;
	background-color : #00095B;
}
#createUsersSelect .p-selectbutton .p-button.p-highlight{
	background: #00095B;
	border-color: black;
	color: #f2f2f2;
}
#createUsersSelect .p-selectbutton .p-button{
	background: #f2f2f2;
	border-color: black;
	color: black;
}

#buttonGroup .p-button.p-button-icon-only{
background: #00095B;
color: #f2f2f2;
scale: 1;
}

.highlight{
  color: blue;
}

.required-label::after {
	content: ' *';
	color: red;
}

//Fix for extra grey border
.p-tabview .p-tabview-nav li:not(.p-highlight) .p-tabview-nav-link{
	border-color: #ffffff;
}

.p-tabview .p-tabview-nav li:not(.p-highlight):not(.p-disabled):hover .p-tabview-nav-link{
	border-color: #2861a4 !important;
}

//Fix for whitebackground for tab which hides the left menu arrow icon
.p-tabview .p-tabview-nav li.p-highlight .p-tabview-nav-link,.p-tabview .p-tabview-nav li .p-tabview-nav-link,
.p-tabview .p-tabview-nav{
	background:unset;
}

/* SCROLL CSS */
::-webkit-scrollbar {
	width: 11px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
	background-color: #c2c2c2;
	border-radius: 10px;
	border: 3px solid transparent;
}

::-webkit-scrollbar-track {
	background-color: #f1f1f1;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:active {
	background-color: #6b6b6b; /* Darker color when active */
}
.p-datatable {
	padding: 0 !important;
}

.p-datatable-tbody {
	padding: 0 !important;
}

.p-datatable-row {
	padding: 0 !important;
}

.p-datatable-row-even, .p-datatable-row-odd {
	padding: 0 !important;
}

/* Target padding on individual cells if necessary */
.p-datatable-tbody > tr > td {
	padding: 0.5rem !important;
	text-align: center !important;
}
.p-datatable .p-datatable-thead>tr>th{
	padding: 12px 15px !important;
	text-align: center !important;
}
.p-datatable .p-datatable-tbody>tr>td {
	padding: 0.5rem !important;
	text-align: center !important;
}

.p-tabview-panels{
	box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.08), 0px 3px 4px rgba(0, 0, 0, 0.1), 0px 1px 4px -1px rgba(0, 0, 0, 0.1);
}

.scale-down-2x{
	scale:.8
}

.p-confirm-popup{
	border-radius: 10px;
	padding: 7px;
}

.border-radius-small{
	border-radius: 10px !important;
}

.p-confirm-popup .p-confirm-popup-message{
	white-space: pre-line;
	line-height: 22px;
}

.p-dialog.custom-dialog .p-dialog-content {
	border-radius: 10px;
}

.p-inputswitch:not(.p-inputswitch-checked) .p-inputswitch-slider {
	background: #898989 !important;
}

label.mandatory::before {
	content: "* ";
	color: darkred;
}

.required-label::after {
	content: ' *';
	color: red;
}
p-table .p-button.p-button-link {
  -webkit-user-select: text !important; /* Chrome, Safari */
  -moz-user-select: text !important; /* Firefox */
  -ms-user-select: text !important; /* IE */
  user-select: text !important; /* Standard */
}

.help-menu.p-menu .p-menuitem:not(.p-highlight):not(.active-menu-item):not(.p-disabled).p-focus>.p-menuitem-content:not(:hover) {
	color: #fff;
	background: transparent;
}

.help-menu.p-menu .p-menuitem.active-menu-item>.p-menuitem-content {
	background: #2861a4;
	color: #fff;
}
